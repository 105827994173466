//
// modal
//
//


.modal-content {
	border: 0;
}

.modal-header {
	position: relative;
	align-items: center;
	min-height: rem(100);
	padding: 82px 80px 30px;
	border: 0;
	flex-wrap: wrap;
	.close {
		@include transition;
		@include translate(0,-50%);
		display: block;
		position: absolute;
		top: 50%;
		right: $card-spacer-x;
		width: rem(60);
		height: rem(60);
		border-radius: 50%;
		color: $dark;
		margin: 0;
		padding: 0;
		opacity: 1;
		outline: 0;
		box-shadow: 0;
		background-color: white;

		&:hover {
			background-color: $light;
		}
	}
}

.modal-footer {
	padding: $card-spacer-y $card-spacer-x;
	min-height: rem(100);
	overflow: hidden;
	border-top: 0;

	.container-fluid {
		padding: 0;
	}
}

.modal-title {
	font-size: 40px;
	line-height: 45px;
}

.modal-description {
	margin-bottom: 0px;
}

.modal-body {
	padding: 0px 80px 80px; 
}


// alt
.modal-alt {
	.modal-header {
		height: rem(400);
		max-height: 50vh;

		.close {
			@include translate(0,0);
			top: $card-spacer-x;
		}
	}

	.modal-title {
		margin-bottom: $card-spacer-y;
		font-size: rem(40);
	}
}


// sidebar
.sidebar {
	.modal-dialog {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		max-width: rem(500);
		bottom: 0;
		margin: 0;

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	.modal-content {
		height: 100%;
	}

	.modal-body {
		overflow: scroll;
	}

}



// search
.search {
	.modal-dialog {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		max-width: 100%;
		margin: 0;
	}

	.form-control {
		height: auto;
		margin-right: rem(40);
		padding: 0;
		line-height: rem(30);
		border: 0;
		font-size: $font-size-base;
	}
}



// decorated
.decorated-content {
	> * {
		&:first-child {
			padding-bottom: $card-spacer-x;
		}

		&:not(:first-child) {
			padding-top: $card-spacer-x;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: $grid-gutter-width / 2;
				width: $grid-gutter-width;
				height: $border-width;
				background: $border-color;
			}
		}
	}
}