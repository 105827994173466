//
// figure
//
//


// global
figure {
    margin: 0;
}

img {
  max-width: 100%;
}


// image
.image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &::after {
        @include transition;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: rgba($black, .3);
    }

    &[class*="-overlay"] {
        &::after { opacity: 1; }
    }

    &[class*="-scroll"] {
        height: 125%;
        top: -25%;
    }

    &[class*="-fade"] {
        &:hover::after {
            opacity: 1;
        }
    }

    &[class*="-scale"] {
        @include transition;
        @include scale(1.1);

        &:hover { 
            @include scale(1);
        }
    }
}