// core template fixes and changes
body > section, body > footer {
	@include media-breakpoint-down(sm) {
		padding: 3rem 0;
	}
}
.copyright {
	display: inline-block;
	vertical-align: middle;
	img {

		margin-left: 7px;
		margin-right: 3px;
	}
	@media (max-width: 768px) {
		margin-top: 5px;
	}
}
.cart {
	max-width: none;
}
.navbar-dark .navbar-toggler-icon {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggc3Ryb2tlPScjZmZmJyBzdHJva2Utd2lkdGg9JzInIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBkPSdNNCA3aDIyTTQgMTVoMjJNNCAyM2gyMicvPjwvc3ZnPg==);
}
[aria-expanded="true"] .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z' fill='rgba(0, 0, 0, 0.5)' %3E%3C/path%3E%3C/svg%3E");
}

.navbar-dark [aria-expanded="true"] .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z' fill='white' %3E%3C/path%3E%3C/svg%3E");
}

@include media-breakpoint-down(md) {
	.navbar .navbar-toggler{
		padding: 0;
	}
}

.flex-auto {
	flex: 0 0 auto !important;
}
.header {
	z-index: 1040;
}
.owl-thumbs .owl-thumb-item {
	margin: 0.625rem 0;
}
.product-promo{
	&.product-promo__square {
		border-radius: 0;
		top: 0;
		right: 0;
		height: auto;
		width: auto;
		padding: 5px 9px;
		min-width: 60px;
		font-size: 0.675rem;
		line-height: 1;
	}
	&.product-promo__inline {
		width: auto;
		position: static;
		display: inline-block;
		margin-bottom: 15px;
	}
}

.product-like {
	&.active {
		&:before {
			color: $primary;
			content: "\ebde";
		}
	}
}

.list-group-columns {
	-webkit-flex-direction: row;
	    -ms-flex-direction: row;
	        flex-direction: row;
}
.search .modal-header {
	height: 100px;
}
.modal-header {
	padding-right: 100px;
}

.content,
.frame-content {
	iframe{
		max-width: 100%;
	}
	img {
		margin-bottom: 2em;
		max-width: 100%;
	}

	table {
		width: 100%;
		color: $table-color;
		background-color: $table-bg; // Reset for nesting within parents with `background-color`.
		border: $table-border-width solid $table-border-color;

		th,
		td {
			padding: $table-cell-padding;
			vertical-align: top;
			border-top: $table-border-width solid $table-border-color;
		}

		thead th {
			vertical-align: bottom;
			border-bottom: (2 * $table-border-width) solid $table-border-color;
		}

		tbody + tbody {
			border-top: (2 * $table-border-width) solid $table-border-color;
		}

		th,
		td {
			border: $table-border-width solid $table-border-color;
		}

		thead {
			th,
			td {
				border-bottom-width: 2 * $table-border-width;
			}
		}
	}

	ul {
		padding-left: 20px;
  		list-style: disc;
		margin-bottom: 1em;
	}

	ol {
		list-style: none;
  		counter-reset: my-awesome-counter;
		li {
			counter-increment: my-awesome-counter;
			&::before {
				content: counter(my-awesome-counter);
  				margin-right: 12px;
			}
		}
	}

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		margin-bottom: 0.5em;
		font-weight: 700;
	}

	p:not(:last-child) {
		margin-bottom: 1em;
	}

	blockquote {
		padding: 10px 0px 10px 30px;

		position: relative;
		margin-bottom: 2em;
		&::before {
			content: "";
			width: 3px;
			height: 100%;
			left: 0;
			top: 0;
			position: absolute;
			background-color: #6B4897;
		}
		p {
			margin-bottom: 0px;
		}
	}
}

.table-responsive {
	overflow: auto;
	margin-bottom: 2em;
	table {
		width: 100%;
	}
}

// custom styles here
.visually-hidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	padding:0 !important;
	border:0 !important;
	height: 1px !important; 
	width: 1px !important; 
	overflow: hidden;
}
//frame
.frame {
	padding: 50px 0px;
}

.frame--after-breadcrumbs{
	padding-top: 25px;
}

.btn {
	border-radius: 35px;
}

.header {
	@media (min-width: 1200px) {
		padding: 14px 0px 14px;
	}
	& + .breadcrumbs{
		padding-top: 0;
	}
}

.navbar-brand {
	margin-right: 20px;
	.logo-mobile {
		display: none;
		@media (max-width: 1200px) {
			display: block;
		}
	}
	.logo-desktop {
		@media (max-width: 1200px) {
			display: none;
		}
	}
}

.navbar-light {
	.navbar-brand {
		color: $primary;
		&:hover {
			color: $primary;
		}
		&:visited {
			color: $primary;
		}
	}
}

.header {
	.nav-link {
		color: $text-color;
		&:hover {
			color: $primary
		}
	}
	.navbar-contacts-item {
		color: $primary;
		display: flex;
		font-size: 14px;
		.caption {
			margin-right: 5px;
		}
		a {
			transition: 0.2s all ease;
			&:hover {
				font-weight: bold;
			}
		}
	}
	.navbar-dark {
		.navbar-contacts-item {
			color: white;
		}
	}
}

.frame {
	.btn {
		&:hover {
			background: $secondary;
		}
		@media (min-width: 1200px) {
			font-size: 1.125rem;
		}
	}
	.btn-outline-primary {
		border-width: 2px;
		&:hover{
			background: $secondary;
			border-color: $secondary;
		}
	}
}

.frame-title {
	font-weight: 700;
	@media (min-width: 1200px) {
		font-size: 3.125rem;
		
	}
}

.frame-slider-main {
	padding: 0px;
	.swiper-container {
		
		.container > .row {
			padding: 50px 0px;
		}
		.swiper-slide {
			padding-bottom: 60px;
		}
	}
	.slider-title {
		margin-bottom: 20px;
	}
	.slider-description {
		margin-bottom: 30px;
		
		p {
			color: #fff;
			font-size: 24px;
			line-height: 30px;
		}
	}
}
.frame-slider-main + .frame-blog{	
	padding-top: 100px;
	@media (max-width: 768px){
		padding-top: 50px;
	}
}

.frame-flashlight {
	position: relative;
	min-height: 875px;
	padding: 0px;
	overflow: hidden;
	background-color: #160B24;

	.shape-circle-right {
		position: absolute;
		top: 134px;
		left: 1268px;
		width: 576px;
		height: 576px;
		border-radius: 50%;
		background: #6B4897;
		animation-name: flashlight_right;
		animation-duration: 6s;
  		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-delay: -6s;
	}

	.shape-circle-left {
		position: absolute;
		top: 217px;
		left: -28px;
		width: 576px;
		height: 576px;
		border-radius: 50%;
		background: #6B4897;
		animation-name: flashlight_left;
		animation-duration: 6s;
  		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@keyframes flashlight_right {
		0% {
			top: 217px;
			left: -28px;
		}
		16% {
			top: 414px;
			left: 282px;
		}
		32% {
			top: 578px;
			left: 1146px;
		}
		48% {
			top: 150px;
			left: 1344px;
		}
		64% {
			top: 0px;
			left: 1171px;
		}
		80% {
			top: -138px;
			left: 332px;
		}
		100% {
			top: 217px;
			left: -28px;
		}
	}

	@keyframes flashlight_left {
		0% {
			top: 134px;
			left: 1268px;
		}
		16% {
			top: -95px;
			left: 960px;
		}
		32% {
			top: -138px;
			left: 570px;
		}
		48% {
			top: 323px;
			left: 193px;
		}
		64% {
			top: 516px;
			left: 431px;
		}
		80% {
			top: 480px;
			left: 1035px;
		}
		100% {
			top: 134px;
			left: 1268px;
		}
	}

	.glass {
		position: absolute;
		inset: 0;
		-webkit-backdrop-filter: blur(130px);
		backdrop-filter: blur(130px);
	}

	.logo-desktop {
		top: 202px;
		right: 0px;
		opacity: 5%;
	}

	.logo-wrapper {
		position: absolute;
		inset: 0;
		padding-top: 95px;
		padding-right: 5px;

		display: flex;
		justify-content: flex-end;
	}

	.banner {
		margin-top: 140px;
		padding-bottom: 150px;
		&__title {
			margin-bottom: 34px;
		}

		&__description {
			margin-bottom: 50px;
		
			p {
				color: #fff;
				font-size: 24px;
				line-height: 30px;
			}
		}
		&__actions {
			display: flex;
			flex-wrap: wrap;
			gap: 48px;
		}
	}
}

.breadcrumbs + .frame-blog{	
	padding-top: 25px;
}

.frame-blog {
	.card {
		// word-wrap: normal;
		&:hover {
			.btn {
				background-color: $secondary;
				border-color: $secondary;
			}
		}
		background-color: $secondary;
	}
	.card-title {
		// font-family: $font-family-secondary;
		@media (min-width: 1200px) {
			font-size: 1.6rem;
		}
	}
	.pagination{
		margin-top: 50px;
	}
	
}
.subdirectory {
	.products__wrapper_system {
		// Страница "Информационные системы"
		background-color: unset;
		gap: 46px;
		display: grid;
		@media (min-width: 1200px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (max-width: 1200px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (max-width: 768px) {
			grid-template-columns: 1fr
		}
	}
	.main-product__info {
		opacity: 1;
		
	}
	.main-product__overlay {
		opacity: 0;
	}
}

.products {
	padding: unset;
	margin: unset;
	position: relative;

	.frame-title {
		color: white;
	}

	.btn-outline-primary {
		color: white;
		border-color: white;
	}

	&__header {
		position: absolute;
		top: calc(2vw - 5rem - 46px);
		left: 0;
		right: 0;
	}

	&__wrapper {
		// Раздел "Реализованные продукты" на главной
		background-color: #160b24;
		display: grid;
		$card-width: 317px;
		@media (min-width: calc(#{$card-width} * 6 + 16px)/* 1920px */){
			grid-template-columns: repeat(6, 1fr);
		}
		@media (max-width: calc(#{$card-width} * 6 + 16px)/* 1920px */) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media (max-width: calc(#{$card-width} * 4 + 16px)/* 1280px */) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (max-width: calc(#{$card-width} * 2 + 16px)) {
			grid-template-columns: 1fr
		}
	}
}

.main-product {
	min-width: 317px;
	min-height: 317px;

	width: 100%;
	height: 100%;
	aspect-ratio: 1/1;
	background-color: #160b24;
	position: relative;

	background-size: cover;
	&__info {
		opacity: 0;
		transition: all 0.3s ease-in-out;
		width: 100%;
		height: 100%;
		padding: 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		& .btn {
			background-color: #7FCAD2;
			border-color: #7FCAD2;
			position: absolute;
			bottom: 24px;
			right: 24px;
			width: 10.6%;
			height: 10.6%;
		}
	}

	&__title {
		color: white;
		font-size: 16px;
		font-style: normal;
		line-height: 1.4em;
	}

	&__overlay {
		position: absolute;
		inset: 0;
		background-color: rgba(59, 52, 78, 0.70);
		background-repeat: no-repeat;
		background-position: center;
		opacity: 1;
		transition: all 0.3s ease-in-out;
	}

	&__logo-container {
		position: absolute;
		inset: 0;

		color: #fff;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 24px;

		img {
			max-height: 70px;
		}
	}

	&__announcement {
		color: #fff;
		text-align: center;
		font-size: 16px;
		line-height: 1.36em;
		font-weight: 700;
		font-family: Comfortaa;
		display: none;


		
		line-clamp: 3;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;

		overflow-y: hidden;
		
		padding-left: 24px;
		padding-right: 24px;
	}

	&:hover {
		.main-product__info {
			opacity: 1;
		}
		.main-product__overlay {
			opacity: 0;
		}
		.main-product__announcement {
			display: -webkit-box;
		}
	}
}


.frame-example{
	.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev{
		@media (max-width: 480px){
			display: none;
		}
	}
	.owl-carousel[data-dots=true]{
		margin-bottom: 0;
	}

	.owl-dots{
		width: 50%;
		right: 0;
		left: auto;
		bottom: 30px;
		@media (max-width: 767px){
			width: 100%;
		}
		.owl-dot{
			span{
				background: #6B489780;

			}
			&.active{
				span{
					background: $primary;
				}
			} 
		}
	}
	.owl-item {
		height: 400px;

		@media (max-width: 767px) {
			height: 600px;
		}

		.example-item {
			height: 100%;
		}
	}
}

.example-item {
	position: relative;
	color: $primary;
	@media (min-width: 768px) {
		display: flex;
		.example-img {
			width: 50%;
		}
		.example-content {
			width: 50%;
		}
	}

	.example-img {
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
	}
	.example-content {
		position: relative;
		right: 0px;
		top: 0px;
		// background-color: $primary;
		// color: white;
		text-align: right;
		padding: 30px;
		padding-bottom: 90px;
	}

	.example-list--item {
		text-align: left;
		.title {
			font-weight: 500;
			margin-bottom: 5px;
		}
		.value {
			font-size: 16px;
			line-height: 24px;
		}
		+ .example-list--item {
			margin-top: 40px;
		}
		ul {
			list-style-type: disc;
			padding-left: 20px;
			li + li {
				margin-top: 5px;
			}
		}
		a {
			// color: white;
			&:hover {
				opacity: 0.6;
			}
		}
	}
	.example-link {
		transition: 0.2s all ease;

		&:hover {
			opacity: 0.8;
		}

		&__wrapper {
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			height: 60px;

			display: flex;
			justify-content: center;
			align-items: flex-end;

			// transform: translateX(50%);
			color: #462F63;
			background-color: white;
			box-shadow: 0 -8px 8px white;
		}
	}
	@media (max-width: 1200px) and (min-width: 768px) {
		.example-list--item {
			+ .example-list--item {
				margin-top: 10px;
			}
		}
	}
	@media (max-width: 767px) {
		.example-img {
			img {
				width: 100%;
			}
		}
		.example-content {
			width: 100%;
			position: static;
		}
	}
}

.frame-experience {
	position: relative;
	padding-bottom: 20px;
	.card {
		padding: 100px 60px;
		background-color: $primary;
		transition: 0.2s all ease;
		word-wrap: normal;
		height: 100%;
		@include media-breakpoint-down(sm) {
			padding: 30px 40px; 
		}
		.card-title {
			color: white;
			@media (max-width: 480px) {
				font-size: 1.2rem;
			}
		}
		&:hover {
			background-color: $secondary;
		}
		&.card__lg {
			padding: 167px 60px;
			@media (max-width: 1600px) {
				padding: 142px 60px;
			}
			@include media-breakpoint-down(sm) {
				padding: 30px 40px; 
			}
		}
		.card-body {
			padding: 0px;
			display: flex;
			align-items: center;
		}
		&.equal-30 {
			height: 303px;
		}
	}

	.howe-to-work-grid {
		$grid-gap: 32px;

		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: $grid-gap;
		margin-top: 2.5rem;
		overflow-wrap: anywhere;
		
		@include media-breakpoint-down("sm", $container-max-widths) {
			grid-template-columns: 1fr;
		}
	
		.howe-to-work-card-wrapper {
			display: flex;
			justify-content: flex-start;
			&:nth-child(odd) {
				justify-content: flex-end;
			}

			@include media-breakpoint-down("md", $container-max-widths) {
				justify-content: center;
				&:nth-child(odd) {
					justify-content: center;
				}
			}
			
			.howe-to-work-card {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				row-gap: 32px;
				

				$card-max-widths: (
					// sm: calc(#{map-get($container-max-widths, "sm")} / 2 - #{$grid-gap} * 2),
					sm: map-get($container-max-widths, "sm"),
					// md: calc(#{map-get($container-max-widths, "md")} / 2 - #{$grid-gap} * 2),
					md: map-get($container-max-widths, "md"),
					lg: calc(#{map-get($container-max-widths, "lg")} / 2 - #{$grid-gap} * 2),
					xl: calc(#{map-get($container-max-widths, "xl")} / 2 - #{$grid-gap} * 2)
				);

				@include make-container-max-widths($card-max-widths);	
				width: 100%;
				min-height: 288px;

				@keyframes image-scale {
					from {
						scale: 100%;

					}
					to {
						scale: 98%;
					}
				}

				&__image {
					width: 100%;
					max-height: 176px;
					object-fit: cover;
					border-radius: 11px;
				}
				&__header {
					color:  #462F63;
					font-size: 40px;
					font-style: normal;
					font-weight: 700;
					line-height: 1.25em;

					overflow: hidden;

					display: -webkit-box;
					line-clamp: 3;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;

					margin: 0;

				}

				&__subheader {
					margin: 0;

					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 1.8em; /* 180% */

					&_white {
						margin-bottom: 24px;
					}
				}

				&__blur {
					backdrop-filter: blur(4px);
					padding: 16px;
					border-radius: 20px;
				}

				.btn {
					max-width: max-content;
				}
		}
			background-color: #F4F6F8;
			padding: 32px;

			&:hover {
				.howe-to-work-card__header {
					color: #7FCAD2;
				}
				.howe-to-work-card__image {
					animation-name: image-scale;
					animation-duration: 0.5s;
					scale: 98%;
					box-shadow: 0px 0px 20px 0px rgba(126, 202, 211, 0.70);
				}
			}

			&_end {
				background: url("/static/images/main_light.svg") #F4F6F8 50% / cover no-repeat ;
				.howe-to-work-card {
					justify-content: flex-end;
					gap: 0;
					
				}

				&:hover {
					.howe-to-work-card__header {
						color: $primary;
					}
				}
			}
		}

		
	}
}
.btn-primary {
	&:hover {
		border-color: $secondary;
	}
}
.frame-promo {
	background-color: $secondary;
	color: #fff;
	.title {
		color: #fff;	
	}
	.btn {
		@media (max-width: 768px) {
			margin-top: 20px;
		}
		&:hover {
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}

.footer {
	padding: 43px 0px;
	background-color: white;
	color: rgba(0,0,0, 0.6);
	border-top: 2px solid var(--color-grey-87, #DDD);
	a {
		color: rgba(0,0,0, 0.6);
		font-size: 12px;
		&:hover {
			color: #000;
		}
	}
	.list-group-columns .list-group-item {
		width: 33.33%;
		@media (max-width: 991px) {
			width: 50%;
		}
		// @media (max-width: 768px) {
		// 	width: 100%;
		// }
	}
	.footer-contacts {
		font-size: 12px;
		a {
			display: block;
			margin-bottom: 10px;
		}
		.footer-phone {
			font-size: 20px;
			@media (max-width: 1200px) {
				font-size: 16px;
			}
		}
		@media (max-width: 768px) {
			margin-top: 10px;
		}
	}
	.navbar {
		padding: 0px;
	}
	.nav-icons {
		margin: 0px;
		.nav-link {
			color: #fff;
			border: 1px solid #fff;
			border-radius: 50%;
			opacity: 0.6;
			width: 38px;
			height: 38px;
			position: relative;
			padding: 0;
			i{
				position: absolute;
				width: auto;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
			[class^="icon-svg-"], [class*=" icon-svg-"] {
				width: 1.5em;
				height: 1.5em;
			}
			+ .nav-link {
				margin-left: 10px;
			}
			&:hover {
				opacity: 1;
			}
		}
		@media (max-width: 1200px) {
			margin-top: 15px;
		}
	} 
	.footer-bottom {
		font-size: 12px;
	}
	.copy {
		display: inline-block;
		margin-right: 10px;
		@media (max-width: 768px) {
			display: block;
			margin-bottom: 5px;
			margin-right: 0px;
		}
	}
	.logo-desktop {
		color: #6B4897;
	}	
}

.pagination {
	.page-link {
		border-radius: 50%;
	}
}

.vacancies-item {
	display: flex;
	background-color: $primary;
	+ .vacancies-item {
		margin-top: 45px;
	}
	.vacancies-img {
		width: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.vacancies-title {
		margin-bottom: 40px;
	}
	@media (max-width: 1200px) {
		flex-wrap: wrap;
	}
	@media (min-width: 1200px) {
		.vacancies-img {
			width: 50%;
		}
	}
}

.vacancies-content {
	padding: 50px;
	color: #fff;
	text-align: right;
	width: 100%;
	position: relative;
	
	.vacancies-title {
		color: #fff;
		text-align: left;
	}
	.vacancies-description {
		text-align: left;
		margin-bottom: 10px;
	}
	.btn {
		display: inline-block;
		margin-left: auto;
		&:hover{
			border-color: $primary;
			color: #fff;
		}
		@media (min-width: 1200px) {
			position: absolute;
			bottom: 25px;
			right: 50px;
		}
	}
	@media (min-width: 1200px) {
		width: 50%;
		padding-bottom: 85px;
	}
	@media (max-width: 1200px) {
		padding-bottom: 25px;
	}
	@media (max-width: 768px) {
		padding: 25px;
	}
}


//partner
.partner-item {
	display: flex;
	background-color: $primary;
	margin: 0px 15px;
	+ .partner-item {
		margin-top: 45px;
	}
	.partner-img {
		width: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.partner-title {
		margin-bottom: 40px;
	}
	@media (max-width: 1200px) {
		flex-wrap: wrap;
	}
	@media (min-width: 1200px) {
		.partner-img {
			width: 50%;
		}
	}
}

.partner-content {
	padding: 50px;
	color: #fff;
	text-align: right;
	width: 100%;
	position: relative;
	
	.partner-title {
		color: #fff;
		text-align: left;
	}
	.partner-description {
		text-align: left;
		margin-bottom: 10px;
	}
	.btn {
		display: inline-block;
		margin-left: auto;
		&:hover{
			border-color: $primary;
			color: #fff;
		}
		@media (min-width: 1200px) {
			position: absolute;
			bottom: 25px;
			right: 50px;
		}
	}
	@media (min-width: 1200px) {
		width: 50%;
		padding-bottom: 85px;
	}
	@media (max-width: 1200px) {
		padding-bottom: 25px;
	}
	@media (max-width: 768px) {
		padding: 25px;
	}
}


//client
.client-item {
	display: flex;
	background-color: $primary;
	+ .client-item {
		margin-top: 45px;
	}
	.client-img {
		width: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.client-title {
		margin-bottom: 40px;
	}
	@media (max-width: 1200px) {
		flex-wrap: wrap;
	}
	@media (min-width: 1200px) {
		.client-img {
			width: 50%;
		}
	}
}

.client-content {
	padding: 50px;
	color: #fff;
	text-align: right;
	width: 100%;
	position: relative;
	
	.client-title {
		color: #fff;
		text-align: left;
	}
	.client-description {
		text-align: left;
		margin-bottom: 10px;
	}
	.btn {
		display: inline-block;
		margin-left: auto;
		&:hover{
			border-color: $primary;
			color: #fff;
		}
		@media (min-width: 1200px) {
			position: absolute;
			bottom: 25px;
			right: 50px;
		}
	}
	@media (min-width: 1200px) {
		width: 50%;
		padding-bottom: 85px;
	}
	@media (max-width: 1200px) {
		padding-bottom: 25px;
	}
	@media (max-width: 768px) {
		padding: 25px;
	}
}

.form-group__fileinput {
	.caption {
		width: 100%;
		padding: 15px;
		display: inline-block;
		cursor: pointer;
		border: 1px dashed #FFFFFF;
		text-align: center;
		color: #fff;
		
		span {
			position: relative;
			&::before {
				content: "";
				position: absolute;
				left: -25px;
				top: 50%;
				margin-top: -10px;
				width: 20px;
				height: 20px;
				transition: 0.2s all ease;
				background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1529 7.28384C18.8266 6.95926 18.299 6.96071 17.9744 7.287L7.95668 17.3589C6.657 18.6586 4.54613 18.6586 3.24403 17.3572C1.94291 16.0554 1.94291 13.9445 3.24419 12.6432L13.5584 2.27478C14.3699 1.46339 15.6891 1.46339 16.5028 2.27653C17.3164 3.09007 17.3164 4.40912 16.5027 5.22289L7.95824 13.7672C7.9577 13.7678 7.95723 13.7684 7.95668 13.769C7.63116 14.0927 7.1051 14.0922 6.78025 13.7674C6.45485 13.442 6.45485 12.9146 6.78025 12.5892L10.9046 8.46402C11.23 8.13855 11.2299 7.61088 10.9044 7.28548C10.579 6.96008 10.0513 6.96012 9.7259 7.2856L5.60163 11.4107C4.62539 12.3869 4.62539 13.9696 5.60171 14.9459C6.57798 15.9222 8.16067 15.9222 9.13698 14.9459C9.13811 14.9448 9.13901 14.9435 9.14011 14.9424L17.6812 6.40136C19.1458 4.93671 19.1458 2.56236 17.6812 1.09772C16.2163 -0.366068 13.8421 -0.366068 12.3783 1.09772L2.06409 11.4662C0.113495 13.4168 0.113495 16.5827 2.06549 18.5357C4.01878 20.488 7.18471 20.488 9.13682 18.5359L19.1562 8.46238C19.4807 8.13605 19.4792 7.60842 19.1529 7.28384Z' fill='white'/%3E%3C/svg%3E%0A");
			}
		}
		&:hover {
			border-color: $primary;
			span {
				&::before {
					background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1529 7.28384C18.8266 6.95926 18.299 6.96071 17.9744 7.287L7.95668 17.3589C6.657 18.6586 4.54613 18.6586 3.24403 17.3572C1.94291 16.0554 1.94291 13.9445 3.24419 12.6432L13.5584 2.27478C14.3699 1.46339 15.6891 1.46339 16.5028 2.27653C17.3164 3.09007 17.3164 4.40912 16.5027 5.22289L7.95824 13.7672C7.9577 13.7678 7.95723 13.7684 7.95668 13.769C7.63116 14.0927 7.1051 14.0922 6.78025 13.7674C6.45485 13.442 6.45485 12.9146 6.78025 12.5892L10.9046 8.46402C11.23 8.13855 11.2299 7.61088 10.9044 7.28548C10.579 6.96008 10.0513 6.96012 9.7259 7.2856L5.60163 11.4107C4.62539 12.3869 4.62539 13.9696 5.60171 14.9459C6.57798 15.9222 8.16067 15.9222 9.13698 14.9459C9.13811 14.9448 9.13901 14.9435 9.14011 14.9424L17.6812 6.40136C19.1458 4.93671 19.1458 2.56236 17.6812 1.09772C16.2163 -0.366068 13.8421 -0.366068 12.3783 1.09772L2.06409 11.4662C0.113495 13.4168 0.113495 16.5827 2.06549 18.5357C4.01878 20.488 7.18471 20.488 9.13682 18.5359L19.1562 8.46238C19.4807 8.13605 19.4792 7.60842 19.1529 7.28384Z' fill='%236B4897'/%3E%3C/svg%3E%0A");
				}
			}
		}
	}
	margin-bottom: 20px;
}

.form-callback {
	.form-group {
		margin-bottom: 30px;
		label{
			text-transform: none;
		}
	}
	.form-group__fileinput {
		.label {
			margin-bottom: 10px;
		}
		label {
			color: $cust-color-dark-gray;
			border-color: $cust-color-dark-gray;
			&:hover {
				color: $primary;
				span {
					&::before {
						background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1529 7.28384C18.8266 6.95926 18.299 6.96071 17.9744 7.287L7.95668 17.3589C6.657 18.6586 4.54613 18.6586 3.24403 17.3572C1.94291 16.0554 1.94291 13.9445 3.24419 12.6432L13.5584 2.27478C14.3699 1.46339 15.6891 1.46339 16.5028 2.27653C17.3164 3.09007 17.3164 4.40912 16.5027 5.22289L7.95824 13.7672C7.9577 13.7678 7.95723 13.7684 7.95668 13.769C7.63116 14.0927 7.1051 14.0922 6.78025 13.7674C6.45485 13.442 6.45485 12.9146 6.78025 12.5892L10.9046 8.46402C11.23 8.13855 11.2299 7.61088 10.9044 7.28548C10.579 6.96008 10.0513 6.96012 9.7259 7.2856L5.60163 11.4107C4.62539 12.3869 4.62539 13.9696 5.60171 14.9459C6.57798 15.9222 8.16067 15.9222 9.13698 14.9459C9.13811 14.9448 9.13901 14.9435 9.14011 14.9424L17.6812 6.40136C19.1458 4.93671 19.1458 2.56236 17.6812 1.09772C16.2163 -0.366068 13.8421 -0.366068 12.3783 1.09772L2.06409 11.4662C0.113495 13.4168 0.113495 16.5827 2.06549 18.5357C4.01878 20.488 7.18471 20.488 9.13682 18.5359L19.1562 8.46238C19.4807 8.13605 19.4792 7.60842 19.1529 7.28384Z' fill='%236B4897'/%3E%3C/svg%3E%0A");
					}
				}
			}
		}
		.caption {
			span {
				&::before {
					background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1529 7.28384C18.8266 6.95926 18.299 6.96071 17.9744 7.287L7.95668 17.3589C6.657 18.6586 4.54613 18.6586 3.24403 17.3572C1.94291 16.0554 1.94291 13.9445 3.24419 12.6432L13.5584 2.27478C14.3699 1.46339 15.6891 1.46339 16.5028 2.27653C17.3164 3.09007 17.3164 4.40912 16.5027 5.22289L7.95824 13.7672C7.9577 13.7678 7.95723 13.7684 7.95668 13.769C7.63116 14.0927 7.1051 14.0922 6.78025 13.7674C6.45485 13.442 6.45485 12.9146 6.78025 12.5892L10.9046 8.46402C11.23 8.13855 11.2299 7.61088 10.9044 7.28548C10.579 6.96008 10.0513 6.96012 9.7259 7.2856L5.60163 11.4107C4.62539 12.3869 4.62539 13.9696 5.60171 14.9459C6.57798 15.9222 8.16067 15.9222 9.13698 14.9459C9.13811 14.9448 9.13901 14.9435 9.14011 14.9424L17.6812 6.40136C19.1458 4.93671 19.1458 2.56236 17.6812 1.09772C16.2163 -0.366068 13.8421 -0.366068 12.3783 1.09772L2.06409 11.4662C0.113495 13.4168 0.113495 16.5827 2.06549 18.5357C4.01878 20.488 7.18471 20.488 9.13682 18.5359L19.1562 8.46238C19.4807 8.13605 19.4792 7.60842 19.1529 7.28384Z' fill='%23888888'/%3E%3C/svg%3E%0A");
				}
			}
		}
	}
}

.frame-contacts {
	padding-bottom: 0px;
}

.frame-callback {
	.frame-title {
		margin-bottom: 10px;
	}
	.description {
		margin-bottom: 30px;
		p {
			line-height: 20px;
		}
	}
	.accordion {
		margin-bottom: 20px;
		.card {
			border: none;
			.card-header {
				margin-bottom: 10px;
				a {
					font-size: 16px;
					line-height: 20px;
					text-decoration: underline;
				}
			}
			.card-body {
				p {
					font-weight: 600;
					font-size: 18px;
					line-height: 22px;
	
					color: #000000;
				}
			}
		}
	}
}


.contacts-item {
	margin-bottom: 40px;
}

.contacts-item--title {
	font-weight: 700;
}

.frame-promo {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
	// &::before {
	// 	content: "";
	// 	width: 100%;
	// 	height: 100%;
	// 	position: absolute;
	// 	top: 0px;
	// 	background: rgba(17, 29, 52, 0.5);
	// }
}

.features-list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;
	justify-content: center;
}

.features-item {
	width: 33.33%;
	text-align: center;
	margin-bottom: 30px;
	@media (max-width: 768px) {
		width: 50%;
	}
	@media (max-width: 420px) {
		width: 100%;
	}
	&-img{
		position: relative;
		width: 38px;
		height: 38px;
		margin: 0 auto 25px;
	}
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		margin-bottom: 0;
	}
}

.features-title {
	font-weight: 400;
	font-size: 1rem;
}


.nav-tabs {
	display: flex;
}

.frame-tabs{
	.nav-tabs{
		display: flex;
		justify-content: center;
	}
	.tab-content{
		.tab-pane{
			width: 100%;
			margin: 0 auto;
			@media (min-width: 768px){
				flex: 0 0 83.33333%;
				max-width: 83.33333%;
			}
			@media (min-width: 1200px){
				flex: 0 0 66.66667%;
				max-width: 66.66667%;
			}
		}
	}
}

.nav-tabs-icon {
	margin-bottom: 0px;
	.nav-item--title {
		font-size: 24px;
		margin-bottom: 10px;
		font-weight: 400;
		text-transform: none;
	}
	.nav-item {
		width: 33.33%;
		text-align: center;
		margin-right: 0px !important;
		padding: 0px 15px;
		margin-bottom: 50px;
		margin-top: 50px;
		img{
			width: 80px;
			height: 80px;
		}
		@media (max-width: 1200px) {
			width: 50%;
		}
		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 25px;
			margin-top: 25px;
		}
		.active {
			.nav-item--title {
				color: $primary;
			}
		}
	}
}

.eyebrow {
	text-transform: none;
  }

.lang {
	margin-top: 1rem;
	@media (min-width: 1201px) {
		position: absolute;
		bottom: 100%;
		right: 0;
		margin-top: 0;
		.dropdown-menu{
			left: auto;
			right: 0;
			min-width: 5rem;
		}
	}
	@media (max-width: 1200px) {
		.dropdown .dropdown-menu{
			position: static;
			float: none;
		}
	}
	
}

.btn-lang {
	border: 1px solid $text-color;
	background: transparent;
	font-size: .875rem;
	padding:  .1rem .4rem .1rem .4rem;
	color: $text-color;
	opacity: 0.6;
	&:hover{
		opacity: 1;
	}
	&::after {
		display: inline-block;
		margin-left: .255em;
		vertical-align: .255em;
		content: "";
		border-top: .3em solid;
		border-right: .3em solid transparent;
		border-bottom: 0;
		border-left: .3em solid transparent;
	}

	&[aria-expanded="true"] {
		&::after {
			@include rotate(180deg);
		}
	}
	@media (max-width: 1199.98px) {
		border: none;
		padding: 5px 0;
	}
}

.icon--va-middle{
	vertical-align: middle;
}

//breadcrumbs
.breadcrumbs{
	padding: 1.25rem 0 0 ;
}

//404

.img-resp{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.content-404{
	height: calc( 100vh - 113px);
	@media (max-width: 1200px){
		height: calc( 100vh - 93px);
	}
}

.map {
	height: 500px;
}

.mapBlock__content{
	display: none;
}

.image[class*="-overlay"]::after{
	display: none;
}


.swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet:only-child{
	display: none;
}

.modal-body {
	@media (max-width: 480px){
		padding: 0 16px 40px;
	}
}
.modal-header {
	.close{
		top: 90px;
		@media (max-width: 480px){
			right: 0;
			top: 60px;
			height: 3rem;
			width: 3rem;
		}
	}
	@media (max-width: 480px){
		padding: 42px 16px 30px;
	} 
}

table:not([class]) {
	max-width: 100%;
	color: $table-color;
	background-color: $table-bg; // Reset for nesting within parents with `background-color`.
	border: $table-border-width solid $table-border-color;

	th,
	td {
		padding: $table-cell-padding;
		vertical-align: top;
		border-top: $table-border-width solid $table-border-color;
	}

	thead th {
		vertical-align: bottom;
		border-bottom: (2 * $table-border-width) solid $table-border-color;
	}

	tbody + tbody {
		border-top: (2 * $table-border-width) solid $table-border-color;
	}

	th,
	td {
		border: $table-border-width solid $table-border-color;
	}

	thead {
		th,
		td {
			border-bottom-width: 2 * $table-border-width;
		}
	}
}

ul:not([class]) {
	padding-left: 20px;
	list-style: disc;
	li{
		margin-bottom: 1rem;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

ol:not([class]) {
	list-style: none;
	  counter-reset: my-awesome-counter;
	li {
		counter-increment: my-awesome-counter;
		margin-bottom: 1rem;
		&::before {
			content: counter(my-awesome-counter);
			  margin-right: 12px;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: 0.5em;
	font-weight: 700;
}

h1, .h1{
	@media (max-width: 480px){
		font-size: 1.5rem;
	}
}

h2, .h2{
	@media (max-width: 480px){
		font-size: 1.25rem;
	}
}

h3, .h3{
	@media (max-width: 480px){
		font-size: 1rem;
	}
}

h4, .h4{
	@media (max-width: 480px){
		font-size: 1rem;
	}
}





blockquote {
	padding: 10px 0px 10px 30px;

	position: relative;
	margin-bottom: 2em;
	margin-top: 2em;
	&::before {
		content: "";
		width: 2px;
		height: 100%;
		left: 0;
		top: 0;
		position: absolute;
		background-color: #6B4897;
	}
	p {
		margin-bottom: 0px;
	}
}

.frame-preview{
	background: #6b4897;
}
.dropdown{
	.dropdown-menu{
		.dropdown-item{
			@media (max-width: 1200px){
				white-space: normal;
			}
		}
		@media (max-width: 1200px){
			box-shadow: none;
			padding: 15px 0;
		}
	}

	.dropdown-menu-dark {
		background-color: #160B24;
		box-shadow: 0 0 30px 0 rgba(255, 255, 255, .2);
		@media (max-width: 1200px){
			box-shadow: none;
		}
		.dropdown-item:hover {
			color: white;
		}
	}
}

.navbar .dropdown-menu.show.show-right{
	left: auto;
	right: 0;
}

.frame-blog, .frame-example, .frame-experience{
	@media (max-width: 768px) {
		padding-bottom: 0px;
	}
	.row:first-child{
		@media (max-width: 768px) {
			margin-bottom: 1em;
		}
	}
}

.frame-experience{
	.mb-3{
		&:last-child{
			@media (max-width: 767px) {
				margin-bottom: 0!important;
			}
		}	
	}
}

.frame-news {
	.row:first-child{
		@media (max-width: 768px) {
			margin-bottom: 1em;
		}
	}
	.mb-6{
		&:last-child{
			@media (max-width: 767px) {
				margin-bottom: 0!important;
			}
		}	
	}
	.pagination{
		@media (max-width: 768px){
			margin-bottom: 0!important;
		}
	}

	@media (min-width: 1200px) {
		.news-item {
			&:nth-child(n + 4) {
				display: none;
			}
		}
	}
}


.display-2 {
	@media (max-width: 480px){
		font-size: 1.5rem;
	}
}
.frame-slider-main .slider-description p {
	@media (max-width: 480px){
		font-size: 1.25rem;
	}
}

.frame-title{
	@media (max-width: 480px){
		font-size: 1.5rem;
	}
}

.pagination{
	@media(max-width: 768px){
		margin-bottom: 50px!important;
	}
}



.frame-blog .card{
	background-color: transparent;
}

.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	grid-auto-flow: dense;
	@media (max-width: 1200px){
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 767px){
		grid-template-columns: 1fr;
	}

	.cell{
		.image--for-desktop{
			display: none;
		}
		@media (min-width: 1201px) {
			.image--for-desktop{
				display: block;
			}		
		}
		&:nth-child(odd){
			@media (min-width: 1201px) {
				grid-row-end: span 2;
					.card {
						&:before{
							padding-top: 150%;
						}
					}
					.image--for-desktop{
						display: block;
					}				
			}
		}
		@media (min-width: 1200px){
			&:nth-child(4){
				grid-column-start: 1;
				grid-column-end: 2;
			}
			&:nth-child(6n+10){
				grid-column-start: 1;
				grid-column-end: 2;
			}
		}
	}
	.card {
		border: none;
		position: relative;
		height: 100%;
		&:before{
			content: '';
			display: block;
			padding-top: 100%;
		}
		a {
			position: absolute;
			height: 100%;
			width: 100%;
		}
		.card-header{
			border: none;
			position: absolute;
			top: .9375rem;
			left: 0;
			right: 0;
			border: 0;
			background: 0 0;
		}
		.card-footer {
			border: none;
			position: absolute;
			bottom: .9375rem;
			left: 0;
			right: 0;
			border: 0;
			background: 0 0;
		}
	}
}


.custom-switch .custom-control-label::before{
	top: 1px;
}

.custom-switch .custom-control-label::after {
	top: calc(1px + 2px)
}

.pagination {
	li + li {
		margin-left: 1rem;
	}
}

button.mfp-close{
	display: none;
}


.navbar-nav {
    @media (max-width: 1199.98px) {
		margin-top: 0;
	}
}

.frame-news.frame--after-breadcrumbs {
	.row{
		margin-bottom: 0;
	}
	.row:not(:last-child){
		margin-bottom: 0;
	}
	.col-md-6.col-lg-4.mb-6{
		margin-bottom: 50px!important;
	}
	.frame-title {
		margin-bottom: 50px;
	}
}

.page-link{
	font-size: .875rem;
}

.grecaptcha-badge{
	display: none!important;
}

.footer {
	.captcha-message{
		font-size: .75rem;
	}
}


.vacancies-list {
	margin-bottom: 2.5rem;
}