//
// spacing
//
//


// gutter
$gutter-list: ();
$gutter-list: map-merge((
  1: 10,
  2: 20,
  3: 30,
  4: 40,
  5: 50,
), $gutter-list);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $value in $gutter-list {
      .gutter#{$infix}-#{$name} {
        margin: -#{$value/2}px;

        > * {
          margin-bottom: 0!important;
          padding: #{$value/2}px;
        }
      }
    }

  }
}


// masonry collage alignment fix
.gutter-1.collage {
  .equal-25, {
    &::before {
      padding-top: calc(25% - 5px);
    }
  }
  .equal-50, {
    &::before {
      padding-top: calc(50% - 5px);
    }
  }
  .equal-75, {
    &::before {
      padding-top: calc(75% - 5px);
    }
  }
}

.gutter-0 {
  margin-left: 0;
  margin-right: 0;
  
  > * {
    padding: 0;
  }
}