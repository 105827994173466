//
// nav
//
//

.nav {
	position: relative;

	+ .tab-content {
		margin-top: rem(20);
	}

	.nav-link {
		@include transition;
		font-size: $font-size-sm;
		letter-spacing: .05em;
		color: $gray-600;

		&.active {
			color: $black;
			background: transparent;
		}

		&:hover {
			color: $black;
		}
	}

	.lavalamp-object {
		display: none;
		z-index: 10;
	}

	&.nav-vertical {
		.nav-item {
			display: block;
			width: 100%;
		}
	}
}

// eyebrow
.nav-eyebrow {

	.nav-item + .nav-item {
	  margin-left: rem(20);
	}

	.nav-link {
		padding: 0;
		font-size: rem(12);
		text-transform: uppercase;
		color: $black;
	}
}


.nav-actions {
	.nav-item {
		margin-right: $card-spacer-y;
	}

	.nav-link {
		@extend %underline;
		padding: rem(2) 0;
		font-size: rem(12);
		text-transform: uppercase;
	}
}


// tabs
.nav-tabs {
	display: inline-flex;
	border-bottom: 0;

	&[class*="flex"] {
		.nav-item:not(:last-child) {
			margin-bottom: rem(10);
		}
	}

	.nav-item:not(:last-child) {
		margin-right: rem(20);
	}

	.nav-link {
		padding: 0 0 rem(5) 0;
		border: 0;
		text-transform: uppercase;
	}

	.lavalamp-object {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: $grid-gutter-width!important;
		background: transparent;
		border-bottom: $border-width solid $black;
	}
}



// pills
.nav-pills {
	background: $light;
	display: inline-block;

	&.flex-column {
		display: flex;

		> .nav-item {
			+ .nav-item {
				margin-top: -$border-width;
				margin-left: 0;
			}
		}
	}

	> .nav-item {
		@include transition;
		position: relative;
		float: left;
		border: $border-width solid $border-color;

		+ .nav-item {
			margin-left: -$border-width;
		}

		&:hover {
			border-color: lighten($dark, 55%);
			z-index: 10;
		}

		.nav-link {
			border-color: transparent;

			&:last-child {
				border-color: transparent;
			}
		}

		&.active {
			border-color: $black;
			z-index: 20;
			
			> .nav-link {
				color: $black;
			}
		}
	}

	.nav-link {
		padding: rem(20);
		z-index: 10;
		font-weight: $font-weight-normal;
		font-size: $font-size-base;
		line-height: rem(20);
		border-top: $border-width solid $border-color;
		border-right: $border-width solid $border-color;
		border-left: $border-width solid $border-color;

		+ .nav-link { border-top: $border-width solid transparent; }
		&:last-child { border-bottom: $border-width solid $border-color; }
	}

	.lavalamp-object {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		border: $border-width solid $black;
		z-index: 20;
	}

	.nav {

		.nav-item {
			&.active {
				background: $dark;

				.nav-link {
					color: $white;

					&:hover {
						background: transparent;
					}
				}
			}
		}

		.nav-link {
			padding: rem(10) rem(20);
			font-size: $font-size-sm;

			&:hover {
				background: $border-color;
			}
		}
	}
}

// steps
.nav-steps {
	overflow: hidden;
	border: $border-width solid $border-color;

	.nav-link {
		&.active {
			position: relative;
			
			&::before {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				display: block;
				content: "";
				width: 100vw;
				background: $border-color;
				z-index: -1;
			}
		}
	}
}


// vertical - rename to minimal 
.nav-category {
	.nav-item {

		&:not(:first-child) {
			margin-top: rem(10);
		}

		&.active {
			> .nav-link { color: $black; }
		}

		[data-toggle="collapse"] {
			&::before {
				position: absolute;
			    content: "\eaf7";
			    width: 1em;
			    line-height: 1em;
			    right: 0;
			    top: 50%;
			    font-family: 'icomoon' !important;
			    font-size: $font-size-lg;
			    margin-top: -0.5em;
			    color: $body-color;
			    @include transition(0.2s);
			}

			&[aria-expanded="true"] {
				&::before {
					@include rotate(180deg);
				}
			}
		}

		.nav-link {
			position: relative;
			padding: 0;
			color: $body-color;
			font-size: $font-size-sm;

			&:hover { color: $black; }
		}

		.nav {
			margin-top: rem(5);
			padding: rem(5) rem(10);

			.nav-item {
				&:not(:first-child) {
					margin-top: .5rem;
				}

				.nav-link {
					font-size: $font-size-sm;
				}
			}

		}
	}
}


// icons
.nav-icons {
	margin: rem(-10) 0 0 rem(-10);
	
	.nav-link {
		display: inline-block;
		padding: rem(10);

		i {
			display: block;
		}
	}
}


//
// inverted
//

.text-white {
	.nav {
		.nav-link {
			color: rgba($white, .5);

			&.active {
				color: $white;
			}

			&:hover {
				color: $white;
			}
		}
	}

	.nav-tabs {
		.lavalamp-object {
			border-bottom-color: $white;
		}
	}
}